<script>
import messages from '@/libs/messages/toast'
import store from '@/store'
import usersApi from '@/axios/api/user'
import toast from '@/libs/toast'

export default {
  name: 'SocialLinks',
  components: {
  },
  data() {
    return {
      formSubmitted: false,
    }
  },

  computed: {
    user() {
      return store.getters['user/user']
    },
  },

  methods: {
    async updateUser() {
      this.formSubmitted = true
      const formData = new FormData()
      formData.append('facebook', this.user.facebook)
      formData.append('youtube', this.user.youtube)
      formData.append('instagram', this.user.instagram)
      formData.append('website', this.user.website)

      try {
        await usersApi.update(formData)
        store.commit('user/setUser', this.user)

        toast.success(this.$bvToast, messages.success, messages.socialMediaUpdated)
      } catch (error) {
        console.error(error)
        toast.error(this.$bvToast, messages.success, messages.errorDetails)
      }

      this.formSubmitted = false
    },
  },
}
</script>

<template>
  <div class="row">
    <div class="col-md-8 col-12">
      <div class="card border-0 rounded shadow">
        <div class="card-body">
          <form class="py-4">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group position-relative">
                  <feather-icon
                    icon="GlobeIcon"
                    size="18"
                    class="mr-50"
                  />
                  <label for="websiteLink">الموقع اللإلكتروني</label>
                  <input
                    id="websiteLink"
                    v-model="user.website"
                    name="website"
                    type="text"
                    class="form-control pl-5"
                    placeholder=""
                  >
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group position-relative">
                  <feather-icon
                    icon="FacebookIcon"
                    size="18"
                    class="mr-50"
                  />
                  <label for="facebookLink">فيسبوك</label>
                  <input
                    id="facebookLink"
                    v-model="user.facebook"
                    name="name"
                    type="text"
                    class="form-control pl-5"
                    placeholder=""
                  >
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group position-relative">
                  <feather-icon
                    icon="InstagramIcon"
                    size="18"
                    class="mr-50"
                  />
                  <label for="instagramLink">انستجرام</label>
                  <input
                    id="instagramLink"
                    v-model="user.instagram"
                    name="name"
                    type="text"
                    class="form-control pl-5"
                    placeholder=""
                  >
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group position-relative">
                  <feather-icon
                    icon="YoutubeIcon"
                    size="18"
                    class="mr-50"
                  />
                  <label for="youtubeLink">يوتيوب</label>
                  <input
                    id="youtubeLink"
                    v-model="user.youtube"
                    name="name"
                    type="text"
                    class="form-control pl-5"
                    placeholder=""
                  >
                </div>
              </div>
            </div>
            <!--end row-->
            <div class="row">
              <div class="col-sm-12">
                <input
                  id="submit"
                  :disabled="formSubmitted"
                  type="submit"
                  name="send"
                  class="btn btn-primary"
                  value="حفظ"
                  @click.prevent="updateUser"
                >
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
          <!--end form-->
        </div>
      </div>
    </div>
  </div>
</template>
