import axios from '@/libs/axios'

export default {
  me() {
    return axios.get('user')
  },

  logout() {
    return axios.post('auth/logout')
  },

  update(formData) {
    return axios.post('user', formData)
  },

  addBranch(formData) {
    return axios.post('branches', formData)
  },

  getBranches(userUid) {
    return axios.get(`user/${userUid}/branches`)
  },

  getBranchByUid(uid) {
    return axios.get(`user/branches/${uid}`)
  },

  editBranch(uid, formData) {
    return axios.post(`branches/${uid}/edit`, formData)
  },

  deleteBranch(uid) {
    return axios.delete(`branches/${uid}`)
  },

  getMyItems() {
    return axios.get('user/my-items')
  },

  updatePassword(formData) {
    return axios.post('user/password', formData)
  },

  resetPassword(formData) {
    return axios.post('user/reset-password', formData)
  },

  forgotPassword(formData) {
    return axios.post('user/forgot-password', formData)
  },
}
