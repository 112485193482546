<script>
import { BLink, BSpinner } from 'bootstrap-vue'
import store from '@/store'
import userApi from '@/axios/api/user'
import messages from '@/libs/messages/toast'
import toast from '@/libs/toast'

export default {
  components: {
    BLink,
    BSpinner,
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['generalSettingsUpdated'],

  data() {
    return {
      formSubmitted: false,

      defaultUserImage: [],

      generalInformationMissing: true,

      showSpinner: false,

      branches: [],
    }
  },

  watch: {
    generalSettingsUpdated(newValue) {
      if (newValue === true) {
        this.generalInformationMissing = false
      }
    },
  },

  mounted() {
    const user = this.$store.getters['user/user']
    this.generalInformationMissing = user.companyName.length === 0 || user.image === null

    this.showSpinner = true
    userApi.getBranches(user.uid)
      .then(response => {
        this.showSpinner = false
        this.branches = response.data.data
        user.branches = this.branches
        this.$store.commit('user/setUser', user)
      }).catch(error => {
        this.showSpinner = false
        console.error(error)
      })
  },

  methods: {
    editBranch(index) {
      const branch = this.branches[index]
      store.commit('branches/setBranch', branch)
      this.$router.push({ name: 'account-settings-edit-branch', params: { uid: branch.uid } })
    },

    deleteBranch(index) {
      this.$bvModal.msgBoxConfirm('هل أنت متأكد من الحذف ؟', {
        size: 'sm',
        okTitle: 'موافق',
        cancelTitle: 'إلغاء',
        centered: true,
      })
        .then(value => {
          if (value === false) {
            return
          }

          this.delete(index)
        })
    },

    delete(index) {
      const branch = this.branches[index]
      userApi.deleteBranch(branch.uid)
        .then(() => {
          store.commit('user/deleteBranch', index)
        }).catch(error => {
          console.log(error)
          toast.error(this.$bvToast, messages.error, messages.errorDetails)
        })
    },
  },
}
</script>

<template>
  <div class="card border-0 rounded shadow">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <p
            v-if="generalInformationMissing === true"
            class="alert alert-danger p-2"
            role="alert"
          >
            البيانات العامة غير مكتلمة, أكمل البيانات العامة لتتمكن من إضافة فروع
          </p>
        </div>
        <div
          v-if="generalInformationMissing === false"
          class="col-12 text-right"
        >
          <b-link
            :to="{name: 'account-settings-add-branch'}"
            class="btn btn-lg btn-primary"
          >
            إضافة فرع جديد
          </b-link>
        </div>
      </div>

      <div class="row mt-3">
        <div
          v-if="showSpinner === true"
          class="col-12 text-center py-2"
        >
          <BSpinner
            large
            class="mx-1"
          />
        </div>
        <div
          v-if="showSpinner === false"
          class="col-12"
        >
          <table class="table table-bordered  w-100">
            <thead>
              <th>الاسم</th>
              <th />
            </thead>
            <tbody>
              <tr
                v-for="(branch, index) in branches"
                :key="index"
              >
                <td>{{ branch.name }}</td>
                <td class="">
                  <b-link
                    class="mx-1 text-dark"
                    @click.prevent="editBranch(index)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="18"
                      class="mr-50"
                    />
                  </b-link>
                  <b-link
                    @click.prevent="deleteBranch(index)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="18"
                      class="mr-50"
                    />
                  </b-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>
