<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import usersApi from '@/axios/api/user'
import messages from '@/libs/messages/toast'
import toast from '@/libs/toast'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formSubmitted: false,
      password: '',
      confirmation: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    async updatePassword() {
      this.formSubmitted = true

      const form = {
        password: this.password,
        confirmPassword: this.confirmation,
      }

      try {
        await usersApi.updatePassword(form)
        toast.success(this.$bvToast, messages.success, messages.passwordUpdated)
      } catch (error) {
        console.log(error)
        toast.error(this.$bvToast, messages.error, messages.errorDetails)
      }

      this.formSubmitted = false
    },
  },
}
</script>

<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <b-card>
        <ValidationObserver
          #default="{ invalid }"
        >
          <!-- form -->
          <b-form
            class="py-4"
            @submit.prevent="updatePassword()"
          >
            <b-row>
              <!-- new password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  label="كلمة المرور"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required|min:8"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-new-password"
                        v-model="password"
                        :type="passwordFieldTypeNew"
                        name="password"
                        placeholder=""
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconNew"
                          class="cursor-pointer"
                          @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ new password -->

              <!-- retype password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-retype-new-password"
                  label="إعادة كلمة المرور"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="confirmed"
                    vid="confirmed"
                    rules="required|confirmed:password"
                  >
                    <b-input-group class="input-group-merge">

                      <b-form-input
                        id="account-retype-new-password"
                        v-model="confirmation"
                        :type="passwordFieldTypeRetype"
                        name="confirmed"
                        placeholder=""
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--/ retype password -->

              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="invalid || formSubmitted === true"
                  variant="primary"
                  type="submit"
                  class="mt-1 mr-1"
                >
                  <BSpinner
                    v-if="formSubmitted"
                    small
                    class="mx-1"
                  />

                  حفظ
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-card>
    </div>
  </div>
</template>
