<script>
import ImageUploader from '@/views/components/ImageUplaoder.vue'
import store from '@/store'
import userApi from '@/axios/api/user'
import messages from '@/libs/messages/toast'
import toast from '@/libs/toast'

export default {
  components: {
    ImageUploader,
  },
  data() {
    return {
      formSubmitted: false,

      user: {},

      defaultUserImage: [],
    }
  },

  created() {
    const user = store.getters['user/user']
    if (user.about == null || user.about === 'null') {
      user.about = null
    }

    if (user.image) {
      this.defaultUserImage = [{ path: user.image }]
    }

    this.user = user
  },

  methods: {
    async updateUser() {
      this.formSubmitted = true
      const formData = new FormData()
      formData.append('firstName', this.user.firstName)
      formData.append('lastName', this.user.lastName)
      formData.append('companyName', this.user.companyName)
      if (this.user.about !== undefined) {
        formData.append('about', this.user.about)
      }

      if (this.user.image instanceof File) {
        formData.append('image', this.user.image)
      }

      try {
        const response = await userApi.update(formData)
        store.commit('user/setUser', response.data.data)
        if (response.data.data.image) {
          this.defaultUserImage = [{ path: response.data.data.image }]
        }

        this.$emit('generalSettingsUpdated')

        toast.success(this.$bvToast, messages.success, messages.userInfoUpdated)
      } catch (error) {
        console.error(error)
        toast.error(this.$bvToast, messages.error, messages.errorDetails)
      }

      this.formSubmitted = false
    },

    imagesUpdated(images) {
      if (images.length !== 0) {
        // eslint-disable-next-line prefer-destructuring
        this.user.image = images[0]
      } else {
        this.user.image = null
      }
    },
  },
}
</script>

<template>
  <div class="card border-0 rounded shadow">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="text-md-left text-center d-sm-flex">
            <div class="change-profile-img">
              <ImageUploader
                :data-images="defaultUserImage"
                :multiple="false"
                @imagesUpdated="imagesUpdated"
              />
            </div>
          </div>
        </div>
      </div>

      <form class="">
        <div class="row mt-4">
          <div class="col-md-12">
            <label class="font-weight-bolder py-1">مدير الحساب</label>
          </div>
          <div class="col-md-6">
            <div class="form-group position-relative">
              <label>الإسم الأول</label>
              <input
                id="first"
                v-model="user.firstName"
                name="name"
                type="text"
                class="form-control"
                placeholder=""
              >
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group position-relative">
              <label>الإسم الأخير</label>
              <input
                id="last"
                v-model="user.lastName"
                name="name"
                type="text"
                class="form-control"
                placeholder=""
              >
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-12">
            <div class="form-group position-relative">
              <label>إسم الشركة</label>
              <input
                id="companyName"
                v-model="user.companyName"
                name="name"
                type="text"
                class="form-control"
                placeholder=""
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group position-relative">
              <label>نبذة</label>
              <textarea
                id="comments"
                v-model="user.about"
                name="comments"
                rows="4"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <input
              id="submit"
              :disabled="formSubmitted"
              type="submit"
              name="send"
              class="btn btn-primary"
              value="حفظ"
              @click.prevent="updateUser"
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
