<script>
import AccountSettingBranches from '@/views/account-setting/branches/AccountSettingBranches.vue'
import store from '@/store'
import AccountSettingsGeneralInfo from './AccountSettingsGeneralInfo.vue'

export default {
  components: {
    AccountSettingBranches,
    AccountSettingsGeneralInfo,
  },

  data() {
    return {
      generalSettingsUpdated: false,

      user: {},
    }
  },

  mounted() {
    this.user = store.getters['user/user']
  },

  methods: {
    setGeneralSettingsUpdated() {
      this.generalSettingsUpdated = true
    },
  },
}
</script>

<template>
  <div class="row">
    <div class="col-md-6 col-12">
      <account-settings-general-info
        @generalSettingsUpdated="setGeneralSettingsUpdated()"
      />
    </div>

    <div class="col-md-6 col-12">
      <account-setting-branches
        :branches="user.branches"
        :general-settings-updated="generalSettingsUpdated"
      />
    </div>
  </div>
</template>
